var Tawk_API = Tawk_API || {}, Tawk_LoadStart = new Date();

var app = angular.module("portal",
    [
        'ngRoute',
        'angular.filter',
        'ngFileUpload',
        'multi-select',
        'ngSanitize',
        'ui.select',
        'daterangepicker',
        'ng.deviceDetector'
    ]
)

app.config(function ($routeProvider, $locationProvider) {
    // AVALIAÇAO
    $routeProvider.when("/avaliacao", {
        templateUrl: "/views/avaliacao/avaliacao.html",
        controller: "avaliacao"
    })

    // BANCO
    $routeProvider.when("/banco", {
        templateUrl: "/views/banco/lista.html",
        controller: "banco"
    })
    $routeProvider.when("/banco/adiciona", {
        templateUrl: "/views/banco/form.html",
        controller: "banco"
    })
    $routeProvider.when("/banco/:id_banco", {
        templateUrl: "/views/banco/form.html",
        controller: "banco"
    })


    // BANCO NS
    $routeProvider.when("/banco_ns", {
        templateUrl: "/views/banco_ns/lista.html",
        controller: "banco_ns"
    })
    $routeProvider.when("/banco_ns/adiciona", {
        templateUrl: "/views/banco_ns/form.html",
        controller: "banco_ns"
    })
    $routeProvider.when("/banco_ns/:id_banco", {
        templateUrl: "/views/banco_ns/form.html",
        controller: "banco_ns"
    })

    // BASE CUSTO
    $routeProvider.when("/base_custo", {
        templateUrl: "/views/base_custo/lista.html",
        controller: "base_custo"
    })
    $routeProvider.when("/base_custo/adiciona", {
        templateUrl: "/views/base_custo/form.html",
        controller: "base_custo"
    })
    $routeProvider.when("/base_custo/:id_base_custo", {
        templateUrl: "/views/base_custo/form.html",
        controller: "base_custo"
    })


    // CANDIDATO SUPPORTER
    $routeProvider.when("/candidato_supporter/candidata", {
        templateUrl: "/views/candidato_doutor/candidata.html",
        controller: "candidato_doutor"
    })
    $routeProvider.when("/candidato_supporter/token/:token", {
        templateUrl: "/views/candidato_doutor/cadastra.html",
        controller: "candidato_doutor"
    })
    $routeProvider.when("/candidato_supporter", {
        templateUrl: "/views/candidato_doutor/lista.html",
        controller: "candidato_doutor"
    })
    $routeProvider.when("/candidato_supporter/adiciona", {
        templateUrl: "/views/candidato_doutor/adiciona.html",
        controller: "candidato_doutor"
    })


    // CLIENTE
    $routeProvider.when("/cliente/meus_dados", {
        templateUrl: "/views/cliente/form_detalha_cliente.html",
        controller: "cliente"
    })
    $routeProvider.when("/cliente/integracao", {
        templateUrl: "/views/cliente_integracao/lista.html",
        controller: "cliente_integracao"
    })
    $routeProvider.when("/cliente/integracao/adiciona", {
        templateUrl: "/views/cliente_integracao/form.html",
        controller: "cliente_integracao"
    })
    $routeProvider.when("/cliente/integracao/verifica_autorizacao", {
        templateUrl: "/views/cliente_integracao/form_autorizacao.html",
        controller: "cliente_integracao"
    })
    $routeProvider.when("/cliente/integracao/:id_integracao", {
        templateUrl: "/views/cliente_integracao/form.html",
        controller: "cliente_integracao"
    })
    $routeProvider.when("/cliente/cidade_cliente", {
        templateUrl: "/views/cliente/cidade_cliente.html",
        controller: "cliente"
    })


    // CIDADE
    $routeProvider.when("/cidade_brasil", {
        templateUrl: "/views/cidade_brasil/lista.html",
        controller: "cidade_brasil"
    })
    $routeProvider.when("/cidade_brasil/adiciona", {
        templateUrl: "/views/cidade_brasil/form.html",
        controller: "cidade_brasil"
    })
    $routeProvider.when("/cidade_brasil/:id_cidade", {
        templateUrl: "/views/cidade_brasil/form.html",
        controller: "cidade_brasil"
    })


    // CLIENTE INTERNO STAFF
    $routeProvider.when("/cliente", {
        templateUrl: "/views/cliente/lista.html",
        controller: "cliente"
    })
    $routeProvider.when("/cliente/adiciona/:tipo_pessoa", {
        templateUrl: "/views/cliente/form_cliente.html",
        controller: "cliente"
    })

    // JOBS Cliente
    $routeProvider.when("/cliente/job", {
        templateUrl: "/views/cliente/job_lista.html",
        controller: "job"
    })
    $routeProvider.when("/cliente/job/adiciona", {
        templateUrl: "/views/cliente/job_form.html",
        controller: "job"
    })
    $routeProvider.when("/cliente/job/detalha/:id", {
        templateUrl: "/views/cliente/job_detalha.html",
        controller: "job"
    })
    $routeProvider.when("/cliente/job/acompanhamento_atendimentos", {
        templateUrl: "/views/cliente/atendimento_lista.html",
        controller: "job"
    })

    $routeProvider.when("/cliente/:id_cliente", {
        templateUrl: "/views/cliente/form_cliente.html",
        controller: "cliente"
    })
    // CLIENTE USUÁRIO INTERNO STAFF
    $routeProvider.when("/cliente/:id_cliente/usuario/adiciona", {
        templateUrl: "/views/cliente/form_usuario.html",
        controller: "cliente"
    })
    $routeProvider.when("/cliente/:id_cliente/usuario/:id_usuario", {
        templateUrl: "/views/cliente/form_usuario.html",
        controller: "cliente"
    })
    // CLIENTE OPERAÇÃO INTERNO STAFF
    $routeProvider.when("/cliente/:id_cliente/operacao/adiciona", {
        templateUrl: "/views/cliente/form_operacao.html",
        controller: "cliente"
    })
    $routeProvider.when("/cliente/:id_cliente/operacao/:id_operacao", {
        templateUrl: "/views/cliente/form_operacao.html",
        controller: "cliente"
    })
    // CLIENTE DO CLIENTE
    $routeProvider.when("/cliente_cliente", {
        templateUrl: "/views/cliente_cliente/lista.html",
        controller: "cliente_cliente"
    })
    $routeProvider.when("/cliente_cliente/adiciona", {
        templateUrl: "/views/cliente_cliente/form.html",
        controller: "cliente_cliente"
    })
    $routeProvider.when("/cliente_cliente/:id_cliente_cliente", {
        templateUrl: "/views/cliente_cliente/form.html",
        controller: "cliente_cliente"
    })


    // DOUTOR
    $routeProvider.when("/supporter/dashboard", {
        templateUrl: "/views/doutor/dashboard.html",
        controller: "dashboard"
    })
    $routeProvider.when("/supporter/slack", {
        templateUrl: "/views/doutor/slack.html",
        controller: "doutor"
    })
    $routeProvider.when("/supporter/competencia", {
        templateUrl: "/views/doutor/competencia.html",
        controller: "doutor"
    })
    $routeProvider.when("/supporter/dados_pessoais", {
        templateUrl: "/views/doutor/dados_pessoais.html",
        controller: "doutor"
    })
    $routeProvider.when("/supporter/termo", {
        templateUrl: "/views/doutor/termo.html",
        controller: "doutor"
    })
    $routeProvider.when("/contrato", {
        templateUrl: "/views/doutor/termo.html",
        controller: "doutor"
    })
    $routeProvider.when("/supporter/regras", {
        templateUrl: "/views/doutor/regras_conduta.html",
        controller: "doutor"
    })
    $routeProvider.when("/supporter/candidato_job", {
        templateUrl: "/views/doutor/candidato_job.html",
        controller: "doutor"
    })
    $routeProvider.when("/supporter/candidato_job/token/:token", {
        templateUrl: "/views/doutor/form_candidato_job.html",
        controller: "doutor"
    })
    $routeProvider.when("/supporter/upload", {
        templateUrl: "/views/doutor/upload.html",
        controller: "doutor"
    })
    $routeProvider.when("/supporter/minhas_pendencias", {
        templateUrl: "/views/doutor/lista_pendencia_doutor.html",
        controller: "doutor"
    })
    $routeProvider.when("/supporter/cidade_atendimento", {
        templateUrl: "/views/doutor/cidade_doutor.html",
        controller: "doutor"
    })
    $routeProvider.when("/supporter/extrato/pagamento", {
        templateUrl: "/views/doutor/extrato_pagamento.html",
        controller: "relatorio"
    })

    // SUPPORTER INTERNO STAFF
    $routeProvider.when("/supporter", {
        templateUrl: "/views/doutor/lista.html",
        controller: "doutor"
    })
    $routeProvider.when("/supporter/adiciona", {
        templateUrl: "/views/doutor/form.html",
        controller: "doutor"
    })
    $routeProvider.when("/supporter/pendencia", {
        templateUrl: "/views/doutor/lista_pendencia.html",
        controller: "doutor"
    })
    $routeProvider.when("/supporter/validacao", {
        templateUrl: "/views/doutor/lista_pendencia_validacao.html",
        controller: "doutor"
    })

    // JOBS DOUTOR
    $routeProvider.when("/supporter/job", {
        templateUrl: "/views/doutor/job_lista.html",
        controller: "job"
    })
    $routeProvider.when("/supporter/job/lista/status_simplificado/:status_simplificado", {
        templateUrl: "/views/doutor/job_lista.html",
        controller: "job"
    })
    $routeProvider.when("/supporter/job/detalha/:id", {
        templateUrl: "/views/doutor/job_detalha.html",
        controller: "job"
    })
    $routeProvider.when("/supporter/desativa_conta", {
        templateUrl: "/views/doutor/desativa_conta.html",
        controller: "doutor"
    })

    $routeProvider.when("/supporter/:id_doutor", {
        templateUrl: "/views/doutor/form.html",
        controller: "doutor"
    })
    $routeProvider.when("/supporter/pendencia/adiciona", {
        templateUrl: "/views/doutor/form_pendencia.html",
        controller: "doutor"
    })
    $routeProvider.when("/supporter/pendencia/:id_pendencia", {
        templateUrl: "/views/doutor/form_pendencia.html",
        controller: "doutor"
    })
    $routeProvider.when("/supporter/:id_doutor/validacao", {
        templateUrl: "/views/doutor/detalha_doutor_pendencia.html",
        controller: "doutor"
    })




    // ESPECIALIDADE
    $routeProvider.when("/especialidade", {
        templateUrl: "/views/especialidade/lista.html",
        controller: "especialidade"
    })
    $routeProvider.when("/especialidade/adiciona", {
        templateUrl: "/views/especialidade/form_especialidade.html",
        controller: "especialidade"
    })
    $routeProvider.when("/especialidade/:id_especialidade", {
        templateUrl: "/views/especialidade/form_especialidade.html",
        controller: "especialidade"
    })
    $routeProvider.when("/especialidade/area/adiciona", {
        templateUrl: "/views/especialidade/form_area.html",
        controller: "especialidade"
    })
    $routeProvider.when("/especialidade/area/:id_area", {
        templateUrl: "/views/especialidade/form_area.html",
        controller: "especialidade"
    })
    $routeProvider.when("/especialidade/competencia/adiciona", {
        templateUrl: "/views/especialidade/form_competencia.html",
        controller: "especialidade"
    })
    $routeProvider.when("/especialidade/competencia/:id_competencia", {
        templateUrl: "/views/especialidade/form_competencia.html",
        controller: "especialidade"
    })


    // FERRAMENTA
    $routeProvider.when("/ferramenta/envia_email", {
        templateUrl: "/views/ferramenta/envia_email.html",
        controller: "ferramenta"
    })


    // FINANCEIRO DASHBOARD
    $routeProvider.when("/financeiro", {
        templateUrl: "/views/financeiro/dashboard.html",
        controller: "financeiro"
    })
    // FINANCEIRO lista retorno
    $routeProvider.when("/financeiro/importacao/retorno", {
        templateUrl: "/views/importa_retorno/lista.html",
        controller: "importa_retorno"
    })
    // FINANCEIRO importa retorno pagamento
    $routeProvider.when("/financeiro/importacao/retorno/pagamento", {
        templateUrl: "/views/importa_retorno/form_pagamento.html",
        controller: "importa_retorno"
    })
    $routeProvider.when("/financeiro/importacao/retorno/faturamento", {
        templateUrl: "/views/importa_retorno/form_faturamento.html",
        controller: "importa_retorno"
    })
    // FINANCEIRO EXTRATO FATURAMENTO
    $routeProvider.when("/financeiro/extrato/faturamento", {
        templateUrl: "/views/financeiro/extrato_faturamento.html",
        controller: "financeiro"
    })
    // FINANCEIRO EXTRATO VALIDAÇÃO FECHAMENTO
    $routeProvider.when("/financeiro/extrato/validacao_fechamento", {
        templateUrl: "/views/financeiro/extrato_validacao_fechamento.html",
        controller: "relatorio"
    })
    // FINANCEIRO EXTRATO FATURAMENTO
    $routeProvider.when("/financeiro/extrato/validacao_faturamento", {
        templateUrl: "/views/relatorio/financeiro_extrato_validacao_faturamento.html",
        controller: "relatorio"
    })
    $routeProvider.when("/financeiro/extrato/analise_receita", {
        templateUrl: "/views/financeiro/extrato_analise_receita.html",
        controller: "financeiro"
    })
    // FINANCEIRO EXTRATO CUSTOS CICLOS
    $routeProvider.when("/financeiro/extrato/custos_ciclo", {
        templateUrl: "/views/financeiro/extrato_custos_ciclo.html",
        controller: "relatorio"
    })


    // FLAG
    $routeProvider.when("/flag", {
        templateUrl: "/views/flag/lista.html",
        controller: "flag"
    })
    $routeProvider.when("/flag/adiciona", {
        templateUrl: "/views/flag/form.html",
        controller: "flag"
    })
    $routeProvider.when("/flag/:id_flag", {
        templateUrl: "/views/flag/form.html",
        controller: "flag"
    })


    // FUNCIONALIDADES
    $routeProvider.when("/funcionalidade", {
        templateUrl: "/views/funcionalidade/lista.html",
        controller: "funcionalidade"
    })
    $routeProvider.when("/funcionalidade/adiciona", {
        templateUrl: "/views/funcionalidade/form.html",
        controller: "funcionalidade"
    })
    $routeProvider.when("/funcionalidade/:id_funcionalidade", {
        templateUrl: "/views/funcionalidade/form.html",
        controller: "funcionalidade"
    })


    // FUNCIONÁRIOS
    $routeProvider.when("/funcionario", {
        templateUrl: "/views/funcionario/lista.html",
        controller: "funcionario"
    })
    $routeProvider.when("/funcionario/adiciona", {
        templateUrl: "/views/funcionario/form.html",
        controller: "funcionario"
    })
    $routeProvider.when("/funcionario/:id_funcionario", {
        templateUrl: "/views/funcionario/form.html",
        controller: "funcionario"
    })


    // DEPENDENCIAS DE CURSOS
    $routeProvider.when("/cursos", {
        templateUrl: "/views/cursos/lista_cursos.html",
        controller: "cursos"
    })
    $routeProvider.when("/cursos/:id_curso", {
        templateUrl: "/views/cursos/curso.html",
        controller: "cursos"
    })


    // GRUPOS
    $routeProvider.when("/grupo", {
        templateUrl: "/views/grupo/lista.html",
        controller: "grupo"
    })
    $routeProvider.when("/grupo/adiciona", {
        templateUrl: "/views/grupo/form.html",
        controller: "grupo"
    })
    $routeProvider.when("/grupo/:id_grupo", {
        templateUrl: "/views/grupo/form.html",
        controller: "grupo"
    })


    // JOB STAFF
    $routeProvider.when("/job", {
        templateUrl: "/views/job/lista.html",
        controller: "job"
    })
    $routeProvider.when("/job/lista/meus/:meus", {
        templateUrl: "/views/job/lista.html",
        controller: "job"
    })
    $routeProvider.when("/job/lista/meus/:meus/status_simplificado/:status_simplificado", {
        templateUrl: "/views/job/lista.html",
        controller: "job"
    })
    $routeProvider.when("/job/lista/meus/:meus/status_simplificado/:status_simplificado/sla_vencido/:sla_vencido", {
        templateUrl: "/views/job/lista.html",
        controller: "job"
    })


    $routeProvider.when("/job/adiciona", {
        templateUrl: "/views/job/form.html",
        controller: "job"
    })
    $routeProvider.when("/job/altera/:id", {
        templateUrl: "/views/job/form.html",
        controller: "job"
    })
    $routeProvider.when("/job/detalha/:id", {
        templateUrl: "/views/job/detalha.html",
        controller: "job"
    })

    // LOJA
    $routeProvider.when("/loja/agendamento", {
        templateUrl: "/views/loja/agendamento.html",
        controller: "loja"
    })

    // OPERACAO
    $routeProvider.when("/operacao/detalha", {
        templateUrl: "/views/operacao/detalha.html",
        controller: ""
    })


    // ORIENTACOES
    $routeProvider.when("/orientacao", {
        templateUrl: "/views/orientacao/lista.html",
        controller: "orientacao"
    })
    $routeProvider.when("/orientacao/adiciona", {
        templateUrl: "/views/orientacao/form.html",
        controller: "orientacao"
    })
    $routeProvider.when("/orientacao/:id_orientacao", {
        templateUrl: "/views/orientacao/form.html",
        controller: "orientacao"
    })


    // ORIENTAÇÃO PADRAO
    $routeProvider.when("/orientacao_padrao", {
        templateUrl: "/views/orientacao_padrao/form.html",
        controller: "orientacao_padrao"
    })
    $routeProvider.when("/orientacao_padrao/:forma_atendimento", {
        templateUrl: "/views/orientacao_padrao/detalha.html",
        controller: "orientacao_padrao"
    })


    // PERMISSAO
    $routeProvider.when("/permissao/detalha/:id_grupo", {
        templateUrl: "/views/permissao/detalha.html",
        controller: "permissao"
    })


    // PESSOAS
    $routeProvider.when("/pessoa", {
        templateUrl: "/views/pessoa/lista.html",
        controller: "pessoa"
    })
    $routeProvider.when("/pessoa/adiciona", {
        templateUrl: "/views/pessoa/form.html",
        controller: "pessoa"
    })
    $routeProvider.when("/pessoa/detalha/:id", {
        templateUrl: "/views/pessoa/form.html",
        controller: "pessoa"
    })


    // TIPOS DE SERVICOS
    $routeProvider.when("/tipo_servico", {
        templateUrl: "/views/tipo_servico/lista.html",
        controller: "tipo_servico"
    })
    $routeProvider.when("/tipo_servico/adiciona", {
        templateUrl: "/views/tipo_servico/form.html",
        controller: "tipo_servico"
    })
    $routeProvider.when("/tipo_servico/:id_tipo_servico", {
        templateUrl: "/views/tipo_servico/form.html",
        controller: "tipo_servico"
    })


    // USUARIOS
    $routeProvider.when("/usuario", {
        templateUrl: "/views/usuario/lista.html",
        controller: "usuario"
    })
    $routeProvider.when("/usuario/adiciona", {
        templateUrl: "/views/usuario/form.html",
        controller: "usuario"
    })
    $routeProvider.when("/usuario/detalha/:id", {
        templateUrl: "/views/usuario/form.html",
        controller: "usuario"
    })
    $routeProvider.when("/usuario/altera_senha", {
        templateUrl: "/views/usuario/altera_senha.html",
        controller: "usuario"
    })
    $routeProvider.when("/usuario/reseta_senha/:token", {
        templateUrl: "/views/usuario/reseta_senha.html",
        controller: "usuario"
    })


    // PENDENCIAS
    $routeProvider.when("/pendencia", {
        templateUrl: "/views/pendencia/lista.html",
        controller: "pendencia"
    })
    $routeProvider.when("/pendencia/adiciona", {
        templateUrl: "/views/pendencia/form.html",
        controller: "pendencia"
    })
    $routeProvider.when("/pendencia/:id_pendencia", {
        templateUrl: "/views/pendencia/form.html",
        controller: "pendencia"
    })


    // RELATÓRIOS
    $routeProvider.when("/relatorio", {
        templateUrl: "/views/relatorio/dashboard.html",
        controller: "relatorio"
    })
    $routeProvider.when("/relatorio/demografico", {
        templateUrl: "/views/relatorio/demografico.html",
        controller: "relatorio"
    })
    $routeProvider.when("/relatorio/financeiro/extrato/supporter", {
        templateUrl: "/views/relatorio/financeiro_extrato_doutor.html",
        controller: "relatorio"
    })
    $routeProvider.when("/relatorio/financeiro/extrato/pagamento", {
        templateUrl: "/views/relatorio/financeiro_extrato_pagamento_doutor.html",
        controller: "relatorio"
    })
    $routeProvider.when("/relatorio/financeiro/previsao_pagamento", {
        templateUrl: "/views/relatorio/financeiro_previsao_pagamento.html",
        controller: "relatorio"
    })
    $routeProvider.when("/relatorio/cadastro_supporters", {
        templateUrl: "/views/relatorio/cadastro_doutores.html",
        controller: "relatorio"
    })
    $routeProvider.when("/relatorio/divulgacao_jobs", {
        templateUrl: "/views/relatorio/divulgacao_job.html",
        controller: "relatorio"
    })
    $routeProvider.when("/relatorio/entrega_vendas", {
        templateUrl: "/views/relatorio/entrega_vendas.html",
        controller: "relatorio"
    })
    $routeProvider.when("/relatorio/margem_contribuicao", {
        templateUrl: "/views/relatorio/margem_contribuicao.html",
        controller: "relatorio"
    })
    $routeProvider.when("/relatorio/candidatos_supporter", {
        templateUrl: "/views/relatorio/candidatos_supporter.html",
        controller: "relatorio"
    })
    $routeProvider.when("/relatorio/operacao/pendencias_supporters", {
        templateUrl: "/views/relatorio/operacao_pendencias_supporters.html",
        controller: "relatorio"
    })
    $routeProvider.when("/relatorio/financeiro/conta_azul", {
        templateUrl: "/views/relatorio/financeiro_extrato_conta_azul.html",
        controller: "relatorio"
    })
    $routeProvider.when("/relatorio/operacao/analise_job", {
        templateUrl: "/views/relatorio/operacao_analise_jobs.html",
        controller: "relatorio"
    })
    $routeProvider.when("/relatorio/operacao/capacity", {
        templateUrl: "/views/relatorio/operacao_capacity.html",
        controller: "relatorio"
    })
    $routeProvider.when("/relatorio/operacao/atendimento/supporter", {
        templateUrl: "/views/relatorio/operacao_atendimento_doutor.html",
        controller: "relatorio"
    })
    $routeProvider.when("/relatorio/operacao/atendimento/ticket_medio_clientes", {
        templateUrl: "/views/relatorio/operacao_atendimento_ticket_medio_cliente.html",
        controller: "relatorio"
    })
    $routeProvider.when("/relatorio/operacao/atendimento/ticket_medio_supporters", {
        templateUrl: "/views/relatorio/operacao_atendimento_ticket_medio_supporters.html",
        controller: "relatorio"
    })

    // INTEGRAÇÕES
    $routeProvider.when("/integracao", {
        templateUrl: "/views/integracao/lista.html",
        controller: "integracao"
    })
    $routeProvider.when("/integracao/adiciona", {
        templateUrl: "/views/integracao/form.html",
        controller: "integracao"
    })
    $routeProvider.when("/integracao/callback?:variaveis", {
        templateUrl: "/views/integracao/lista.html",
        controller: "integracao"
    })
    $routeProvider.when("/integracao/:id_integracao", {
        templateUrl: "/views/integracao/form.html",
        controller: "integracao"
    })

    // UM CLIQUE
    $routeProvider.when("/click/deslocamento", {
        templateUrl: "/views/click/acao.html",
        controller: "click"
    })
    $routeProvider.when("/click/checkin", {
        templateUrl: "/views/click/acao.html",
        controller: "click"
    })

    // DASHBOARD
    $routeProvider.when("/dashboard", {
        templateUrl: "/views/dashboard.html",
        controller: "dashboard"
    })


    // ACOMPANHA JOB
    $routeProvider.when("/acompanha_job", {
        templateUrl: "/views/acompanha_job.html",
        controller: "dashboard"
    })


    // LOGOUT
    $routeProvider.when("/logout", {
        templateUrl: "/views/login.html",
        controller: "login"
    })


    // / Login
    $routeProvider.when("/", {
        templateUrl: "/views/login.html",
        controller: "login"
    })

    $routeProvider.when("/sso/discourse/sso/:sso/sig/:sig", {
        templateUrl: "/views/sso.html",
        controller: "sso"
    })



    // DEFAULT ROOUTE
    $routeProvider.otherwise({
        templateUrl: "/views/404.html",
        controller: "erro"
    })
})


// Lib global
app.run(
    function (
        $rootScope,
        $location,
        config,
        $http,
        $interval,
        $timeout,
        $templateCache,
        deviceDetector,
        $window,
        json2csv
    ) {

        $rootScope.opcoes = config.opcoes

        $http({
            url: "/portal/cfg",
            method: 'GET'
        }).success(function (data, status) {
            config = Object.assign(config, data)
            console.log("app_version", config.app_version)
            if (config.app_mode == 'prod') {
                $rootScope.nsacademy = config.moodle_help.prod.base_url
                $rootScope.moodle_help = config.moodle_help.prod
                $rootScope.trackers = config.trackers.prod
            } else if (config.app_mode == 'teste') {
                $rootScope.nsacademy = config.moodle_help.teste.base_url
                $rootScope.moodle_help = config.moodle_help.teste
                $rootScope.portal_alerta = config.app_mode
                $rootScope.trackers = config.trackers.teste
            } else {
                $rootScope.nsacademy = config.moodle_help.dev.base_url
                $rootScope.moodle_help = config.moodle_help.dev
                $rootScope.portal_alerta = config.app_mode
                $rootScope.trackers = config.trackers.dev
            }
            // GOOOGLE ANALYTICS & FACEBOOK PIXEL
            $window.ga = $window.ga || function () { (ga.q = ga.q || []).push(arguments) }; ga.l = +new Date
            $window.ga('create', $rootScope.trackers.google_analytics, 'auto')
            $window.fbq('init', $rootScope.trackers.facebook_pixel);

            $window._linkedin_data_partner_ids = $window._linkedin_data_partner_ids || [];
            $window._linkedin_data_partner_ids.push($rootScope.trackers.linkedin);

            console.log("Iniciando app in modo: ", config.app_mode)
            $rootScope.regras_conduta = $rootScope.nsacademy + $rootScope.moodle_help.course_url + $rootScope.moodle_help.doutor_ids.regras
            $rootScope.discourse_habilitado = config.discourse
        })
            .error(function (data, status) {
                console.log("Falha recuperar configurações do APP. Iniciando em modo de produção: ", data, status)
                $rootScope.nsacademy = config.moodle_help.prod.base_url
                $rootScope.moodle_help = config.moodle_help.prod
                $rootScope.regras_conduta = $rootScope.nsacademy + config.moodle_help.prod.course_url + config.moodle_help.prod.doutor_ids.regras
            })

        $rootScope.debug = function (...dados) {
            if (config.app_mode == 'dev') {
                console.log(...dados)
                // descomente a linha abaixo se quiser saber quem chamou a função...
                // console.trace()
            }
        }

        $rootScope.fecha_modal = function (id_modal) {
            $('#' + id_modal).modal('hide')
        }

        $rootScope.erro_portal = function (data, status) {
            $rootScope.debug(data)
            if (status == 401) {
                alert(data.msg)
                if (!$location.search().destino) {
                    if ($location.path() != '/') {
                        let destino = $location.path()
                        $location.path('/').search('destino', destino)
                    }
                }
                $rootScope.usuario_logado = false
            }
            else
                $rootScope.adiciona_historico(data, status)
        }

        $rootScope.historico_operacoes = []
        $rootScope.adiciona_historico = function (msg, status) {
            if (status >= 400) {
                let status = "erro"
                $('#notificacoes').addClass('alert-danger').fadeIn("slow")
            }
            else {
                let status = "sucesso"
                $('#notificacoes').addClass('alert-success').fadeIn("slow")
            }

            var data_hora = new Date().toLocaleString()

            // Constroi as mensagens para notificação e histórico
            var mensagem_titulo
            if (msg.msg)
                mensagem_titulo = msg.msg
            else
                mensagem_titulo = msg
            var mensagem_complemento = ''
            var mensagem_completa = ''

            if (msg.extra_info) {
                if (typeof msg.extra_info == "string")
                    mensagem_complemento = msg.extra_info
                else {
                    if (_.isEmpty(msg.extra_info))
                        mensagem_complemento = ""
                    else
                        mensagem_complemento = JSON.stringify(msg.extra_info)
                }
            }
            mensagem_completa = mensagem_titulo + (mensagem_complemento != '' ? ' - ' + mensagem_complemento : '')

            // Adiciona notificação abaixo header
            $rootScope.notificacao = mensagem_completa
            $timeout(function () {
                $rootScope.notificacao = ''
                $('#notificacoes').removeClass('alert-danger alert-success').fadeOut("slow")
            }, 4000)

            // Adiciona histórico rodapé
            $rootScope.historico_operacoes.unshift({ data: data_hora, msg: mensagem_completa, status: status })
        }

        $rootScope.limpa_form = function () {
            $("#form")[0].reset()
        }

        $rootScope.redireciona = function (vet) {
            $location.path(vet.join("/"))
        }

        $rootScope.get_geolocation = function (obj_end, scope) {
            if (!obj_end.cep || !obj_end.numero || !obj_end.logradouro || !obj_end.bairro || !obj_end.cidade || !obj_end.estado)
                return
            var geocoder = new google.maps.Geocoder()
            var endereco = obj_end.logradouro + " " + obj_end.numero + " " + obj_end.bairro + " " + obj_end.cidade + " " + obj_end.estado
            geocoder.geocode({ 'address': endereco }, function (results, status) {
                if (status == google.maps.GeocoderStatus.OK) {
                    obj_end.latitude = results[0].geometry.location.lat()
                    obj_end.longitude = results[0].geometry.location.lng()
                    if (scope)
                        scope.$apply()
                }
                else
                    $rootScope.adiciona_historico("Falha recuperar geolocalização", 400)
            })
        }

        $rootScope.get_inicio_fim = function (periodo) {
            let ret = {}
            if (periodo.startDate)
                ret.data_inicio = periodo.startDate.format('YYYY-MM-DD')
            if (periodo.endDate)
                ret.data_fim = periodo.endDate.format('YYYY-MM-DD')
            return ret
        }


        $rootScope.inverte_data_hora = function (data, separador, novo_separador) {
            var vet_dh = data.split("T")
            var vet_data = vet_dh[0].split(separador)
            return vet_data[2] + novo_separador + vet_data[1] + novo_separador + vet_data[0] + " " + vet_dh[1]
        }

        $rootScope.busca_cep = function (cep, obj_endereco, focus_sucesso, focus_falha, executado_por) {
            if (!cep) {
                $rootScope.adiciona_historico("CEP inválido!", 400)
                $('#' + focus_falha).focus()
                return false
            }
            cep = cep.replace(/[^\d]/g, '')
            const valida_cep = /^[0-9]{8}$/

            if (!valida_cep.test(cep)) {
                $rootScope.adiciona_historico("CEP inválido!", 400)
                $('#' + focus_falha).focus()
                return false
            }

            $rootScope.erro_cep_imcompleto = false
            $rootScope.erro_cep_inexistente = false
            $rootScope.erro_cep_falha_interna = false
            $rootScope.informar_todo_endereco = false


            return $http({
                url: 'https://viacep.com.br/ws/' + cep + '/json/',
                method: 'GET'
            }).success(function (data, status) {
                if (data.erro === true) {
                    $rootScope.erro_cep_inexistente = true
                    $rootScope.informar_todo_endereco = false
                    $('#erro_cep').modal('show')
                }
                else if (!data.bairro || !data.logradouro) {
                    $rootScope.informar_todo_endereco = false
                    $rootScope.erro_cep_imcompleto = true

                    if (!executado_por)
                        $('#erro_cep').modal('show')
                }
                else {
                    $('#' + focus_sucesso).focus()
                    $rootScope.desabilita_endereco()
                    if (!data.bairro || !data.logradouro) {
                        $('#logradouro').attr("disabled", false)
                        $('#bairro').attr("disabled", false)
                    }
                }

                obj_endereco.estado = data.uf
                obj_endereco.cidade = data.localidade
                obj_endereco.bairro = data.bairro
                obj_endereco.logradouro = data.logradouro
                obj_endereco.endereco.logradouro = data.logradouro ? true : false;
                obj_endereco.endereco.bairro = data.bairro ? true : false;
            })
                .error(function (data, status) {
                    // Se entrar aqui é falha nossa, enviamos um cep inválido ou ainda o servidor do viacep
                    // pode estar fora do ar, mas nao é falha do usuário
                    $rootScope.informar_todo_endereco = true
                    $rootScope.erro_cep_falha_interna = true
                    $('#erro_cep').modal('show')
                })
        }
        $rootScope.habilita_endereco = function (inexistente) {
            $('#erro_cep').modal('hide')
            if (inexistente) {
                $('#cidade').removeAttr("disabled")
                $('#estado').removeAttr("disabled")
            }
            $('#logradouro').removeAttr("disabled")
            $('#bairro').removeAttr("disabled")
        }
        $rootScope.desabilita_endereco = function () {
            $('#logradouro').attr("disabled", true)
            $('#bairro').attr("disabled", true)
            $('#cidade').attr("disabled", true)
            $('#estado').attr("disabled", true)
        }

        $rootScope.mostra_loader = function (status) {
            if (status == true)
                $('#loader').modal('show')
            else
                $('#loader').modal('hide')
        }

        $rootScope.serializa_vetor = function (vetor) {
            if (!vetor || vetor.constructor !== Array)
                return ""
            return vetor.join('\n')
        }

        $rootScope.mostra_mensagem = function (status, mensagem, botoes, arquivo) {

            $('#mensagem_status').removeClass('texto-sucesso texto-erro')
            $('#mensagem_botoes_adicionais').html('')

            if (arquivo)
                $rootScope.arquivo_alerta = arquivo
            else
                $('#mensagem_texto').html(mensagem)


            if (botoes) {
                for (let botao in botoes) {
                    $('#mensagem_botoes_adicionais').prepend('<a class="btn btn-slim btn-secondary link-redireciona-fecha-modal" href="' + botoes[botao].url_destino + '">' + botoes[botao].titulo + '</a>')
                    $('.link-redireciona-fecha-modal').on('click', () => { $('#mensagem').modal('hide') })
                }
            }

            $rootScope.mensagem_ico_sucesso = false
            $rootScope.mensagem_ico_erro = false

            if (status == 'sucesso') {
                $rootScope.mensagem_ico_sucesso = true
                $('#mensagem_status').text('Sucesso').addClass('texto-sucesso')
            } else if (status == 'erro') {
                $rootScope.mensagem_ico_erro = true
                $('#mensagem_status').text('Erro').addClass('texto-erro')
            } else
                $('#mensagem').modal('hide')


            $('#mensagem').modal('show')
        }

        $rootScope.link_redireciona_fecha_modal = function () {
            $('#mensagem').modal('hide')
        }

        // recebe objeto com {status, icone, titulo, mensagem, url_destino, duracao_redirecionamento}
        $rootScope.mostra_mensagem_nova = function (obj) {
            $('#mensagem_nova').modal('show')
            $rootScope.dados_mensagem_nova = obj
            if (obj.status == 'sucesso' && obj.duracao_redirecionamento) {
                let timer = $interval(() => {
                    $rootScope.dados_mensagem_nova.duracao_redirecionamento = $rootScope.dados_mensagem_nova.duracao_redirecionamento - 1
                    if ($rootScope.dados_mensagem_nova.duracao_redirecionamento == 0) {
                        $('#mensagem_nova').modal('hide')
                        $interval.cancel(timer);
                        $rootScope.login(obj.usuario)
                    }
                }, 1000)
            }
        }

        $rootScope.link_redireciona_fecha_modal = function () {
            $('#mensagem').modal('hide')
        }


        // Detecta dispositivo
        if (['android', 'ios', 'windows phone'].indexOf(deviceDetector.os) >= 0)
            $rootScope.disposivo_movel = true

        // Seta Temporizador usado para chamar conta_validacao_doutor
        $rootScope.temporizador = undefined

        // Usuário logado
        $rootScope.menus = {}
        $rootScope.recarrega_dados_usuario_logado = function (redireciona_pagina_inicial_padrao,
            mostra_alerta_nsacademy,
            mostra_alerta_login) {

            $.ajax({
                type: "GET",
                url: config.base_url + '/menu',
                async: false,
                success: function (data) {
                    if (data.usuario) {

                        $rootScope.menus = data.menu
                        $rootScope.usuario_logado = data.usuario
                        $rootScope.server_version = data.app_version

                        data.menu.filter(function (item) {
                            if ($rootScope.disposivo_movel
                                && $rootScope.usuario_logado.grupo != "Doutor"
                                && item.nome != 'Minhas Solicitações'
                                && item.nome != 'Meus Dados')
                                item.invisivel = true
                            if (item.nome == 'Validação Supporter')
                                item.invisivel = true
                        })

                        // Verifica se existe destino comp parametro da URL.
                        // Se tiver precisa ser redirecionado para URL definida em destino
                        if ($location.search().destino) {
                            redireciona_pagina_inicial_padrao = false
                            $location.path($location.search().destino).search('destino', null)
                        }

                        // usuário acabou de fazer login, redirecionando para destinos padrões
                        if (redireciona_pagina_inicial_padrao) {

                            if (!$rootScope.disposivo_movel) {

                                if ($rootScope.usuario_logado.perfil != 'doutor')
                                    //$location.path('/dashboard')
                                    window.location = '/#/dashboard'
                                else
                                    //$location.path('/supporter/dashboard')
                                    window.location = '/#/supporter/dashboard'
                            }
                            else {
                                if ($rootScope.usuario_logado.perfil == 'doutor')
                                    //$location.path('/supporter/job')
                                    window.location = '/#/supporter/job'
                                else if ($rootScope.usuario_logado.perfil == 'cliente')
                                    $location.path('/cliente/job')
                                else
                                    //$location.path('/job')
                                    window.location = '/#/job'
                            }
                        }

                        if ($rootScope.usuario_logado.grupo == "Doutor") {
                            $rootScope.conta_pendencia_doutor()
                            if (config.ns_academy_doutor_obrigatorio && $rootScope.usuario_logado.id_moodle == null) {
                                $('#doutor_primeiro_login').modal({ backdrop: 'static', keyboard: false })
                                if (mostra_alerta_nsacademy) {
                                    $rootScope.erro_portal('Para prosseguir é necessário autenticar seu usuário no NS Academy.', 400)
                                    $rootScope.mostra_alerta_nsacademy = true
                                }
                            }
                            else {
                                $('#doutor_primeiro_login').modal('hide')
                            }
                            if (redireciona_pagina_inicial_padrao && config.discourse && $rootScope.usuario_logado.usuario_discourse == null)
                                $('#discourse').modal()
                        }
                        else if ($rootScope.usuario_logado.perfil == "funcionario") {
                            if (!$rootScope.contador_jobs)
                                $rootScope.inicializa_tarefas()
                            if (!$rootScope.temporizador ||
                                new Date().getTime() >= $rootScope.temporizador + config.tempo_atualizacao_rotinas * 1000) {
                                $rootScope.temporizador = new Date().getTime()
                                $rootScope.conta_validacao_doutor()
                            }
                            if (redireciona_pagina_inicial_padrao && config.discourse && $rootScope.usuario_logado.usuario_discourse == null)
                                $('#discourse').modal()
                        }
                        else {
                            // cliente
                        }
                    }
                },
                error: function (data) {
                    $rootScope.menus = {}
                    if (mostra_alerta_login)
                        $rootScope.erro_portal(data.responseJSON, data.status)
                }
            })
        }

        $rootScope.inicializa_tarefas = function () {
            // REDIRECT PARA O PORTAL NOVO
            if ($rootScope.usuario_logado && $rootScope.usuario_logado.perfil && $rootScope.usuario_logado.perfil != 'funcionario')
                window.location = localStorage.getItem('redirect')

            if (!$rootScope.contador_jobs) {
                if (["Gestor de Demanda", "Gestor de Operação", "Diretor de Operação", "Gestor de Registro", "Gestor de Associação", "Gestor de Device", "Gestor de Infra" ].indexOf($rootScope.usuario_logado.grupo) >= 0) {
                    $rootScope.conta_job()
                    $rootScope.contador_jobs = $interval($rootScope.conta_job, config.tempo_atualizacao_rotinas * 1000)
                }

            }
        }

        $rootScope.$on('$routeChangeSuccess', function ($event, current, previous) {
            const nao_requer_reload = [
                "/candidato_supporter/candidata",
                "/candidato_supporter/token/:token",
                "/avaliacao",
                "/loja/agendamento",
                "/usuario/reseta_senha/:token",
                "/click/deslocamento",
                "/click/checkin",
                "",
                "/logout",
                "/"
            ]
            if (config.app_version &&
                $rootScope.server_version != config.app_version &&
                nao_requer_reload.indexOf(current.originalPath) == -1) {
                $window.location.reload()
            }
            // Registra os trackers nos respectivos analytics
            $window.ga('send', 'pageview', $location.url())
            $window.fbq('track', "pageView", $location.url())
        })

        // Cancela reload Dashboard Get Jobs.
        $rootScope.$on('$routeChangeStart', function ($event, next, current) {
            $rootScope.debug("next", next.originalPath)
            // $rootScope.debug("current", current)
            // Se são URLS que não requer login
            const nao_requer_login = [
                "/candidato_supporter/candidata",
                "/candidato_supporter/token/:token",
                "/avaliacao",
                "/loja/agendamento",
                "/usuario/reseta_senha/:token",
                "/click/deslocamento",
                "/click/checkin",
                "",
                "/logout",
            ]
            if (nao_requer_login.indexOf(next.originalPath) >= 0) {
                // Não faz nada
                $rootScope.debug("url '" + next.originalPath + "'", "não requer login")
            }
            else if (["/"].indexOf(next.originalPath) != -1) {
                // Se usuário está acessando a raiz verifica se tem sessão ativa redireciona url inicial
                // padrão sem alertar
                $rootScope.debug("url '/' chamando com redireciona")
                $rootScope.recarrega_dados_usuario_logado(true, false, false)
            }
            else {
                // qualquer outra URL verifica se está logado com alerta e redirecinoamento para login
                // se nao tiver sessão e não redireciona para url padrão e sim para url que quer acessar
                $rootScope.debug("url '" + next.originalPath + "' chamando com alerta login")
                $rootScope.recarrega_dados_usuario_logado(false, false, true)

            }

        })

        /** ====================
        * CONTA JOB
        ====================**/
        $rootScope.quantidade_jobs = { aberto: 0, aberto_sla: 0, andamento: 0, andamento_sla: 0 }
        $rootScope.conta_job = function () {
            $http({
                url: config.base_url + '/job/conta',
                method: 'GET'
            }).success(function (data, status) {
                if ($rootScope.quantidade_jobs.aberto < data.aberto)
                    $rootScope.erro_portal('Existem novos Jobs abertos.', 400)

                if ($rootScope.quantidade_jobs.aberto_sla < data.aberto_sla ||
                    $rootScope.quantidade_jobs.andamento_sla < data.andamento_sla)
                    $rootScope.erro_portal('Existem Jobs com o SLA VENCIDO.', 400)

                $rootScope.quantidade_jobs = data
            })
                .error(function (data, status) {
                    $rootScope.erro_portal(data, status)
                })
        }

        /** ====================
        * CONTA PENDENCIA SUPPORTER
        ====================**/
        $rootScope.conta_pendencia_doutor = function () {
            let filtro = {
                status: "pendente",
                id_doutor: $rootScope.usuario_logado.id_doutor
            }
            $http({
                url: config.base_url + "/pendencia_doutor/lista",
                method: 'POST',
                data: filtro
            }).success(function (data, status) {
                $rootScope.quantidade_pendencias_doutor = data.length
                $rootScope.pendencias_doutor = data
            }).error(function (data, status) {
                $rootScope.quantidade_pendencias_doutor = 0
                if (status != 404) {
                    $rootScope.erro_portal(data, status)
                }
            })
        }


        /** ====================
        * CONTA VALIDACAO SUPPORTER
        ====================**/
        $rootScope.conta_validacao_doutor = function () {
            $rootScope.quantidade_validacao_doutor = 0
            $http({
                url: config.base_url + '/doutor/validacao/pendente',
                method: 'GET'
            }).success(function (data, status) {
                $rootScope.quantidade_validacao_doutor = data[0].total_pendencias
            }).error(function (data, status) {
                if (status != 404)
                    $rootScope.erro_portal(data, status)
            })
        }

        $(window).on('shown.bs.modal', function () {
            $('[data-toggle="tooltip"]').tooltip({ placement: 'bottom', container: 'body', html: true })
            if ($rootScope.usuario_logado)
                $('.modal-backdrop').css('bottom', 120)
        });


        $rootScope.carrega_chat = function () {
            if ($rootScope.usuario_logado && $rootScope.usuario_logado.grupo == 'Cliente') {
                (function () {
                    var s1 = document.createElement("script"), s0 = document.getElementsByTagName("script")[0];
                    s1.async = true;
                    s1.src = 'https://embed.tawk.to/58810bd88f538671f477165c/default';
                    s1.charset = 'UTF-8';
                    s1.setAttribute('crossorigin', '*');
                    s0.parentNode.insertBefore(s1, s0);
                })()
            }
        }

        $rootScope.grava_usuario_discourse = function (usuario_discourse) {
            $http({
                url: config.base_url + '/usuario/discourse/' + usuario_discourse,
                method: 'PUT',
            }).success(function (data, status) {
                $rootScope.adiciona_historico(data, status)
                $('#discourse').modal('hide')
            }).error(function (data, status) {
                $rootScope.erro_portal(data, status)
            })
        }

        $rootScope.verifica_usuario_discourse = function () {
            if ($rootScope.usuario_logado.usuario_discourse)
                $window.open(config.url_discourse, '_blank')
            else {
                $('#discourse').modal()
            }
        }

        // Executa após cada template ser renderizado.
        $rootScope.$on('$viewContentLoaded', function () {

            // REDIRECT PARA O PORTAL NOVO
            if ($rootScope.usuario_logado && $rootScope.usuario_logado.perfil && $rootScope.usuario_logado.perfil != 'funcionario' && localStorage.getItem('redirect'))
                window.location = '/#/logout'

            $('[data-toggle="tooltip"]').tooltip({ placement: 'bottom', container: 'body', html: true })

            $('button, a').parent().mouseenter(function () {
                if ($(this).children().attr('disabled'))
                    $(this).css('cursor', 'no-drop')
            })

            // Chama ajuste de altura
            $(window).resize(function () {
                $rootScope.ajusta_altura()
            })
            $timeout(function () {
                $rootScope.ajusta_altura()
            }, 0)
        })


        // Ajusta altura layout
        $rootScope.ajusta_altura = function () {
            $('.menu-list-desktop').height($(window).height() - 70 - 112)
            $('.panel-body').height($(window).height() - 70 - 126 - 170)
            $('.panel-body-financeiro').height($(window).height() - 70 - 150 - 170)
            $('.panel-body-financeiro-legenda').height($(window).height() - 70 - 180 - 170)
            $('.panel-body-header-duplo').height($(window).height() - 70 - 142 - 150)
            $('.panel-body-metade').height(($(window).height() - 70 - 126 - 224) / 2)
            $('.panel-body-metade-horizontal').height(($(window).height() - 70 - 120 - 210) / 2)
            $('.panel-body-metade-horizontal-header').height(($(window).height() - 70 - 120 - 250) / 2)
            $('.panel-body-no-panel-header').height($(window).height() - 70 - 113 - 100)
            $('.panel-body-no-panel-header-pemissao').height($(window).height() - 70 - 126 - 200)
            $('.panel-body-no-panel-footer').height($(window).height() - 70 - 126 - 120)
            $('.panel-body-full').height($(window).height() - 70 - 157)
            $('.panel-body-no-panel-header-foooter').height($(window).height() - 70 - 184)
            $('.panel-body-no-panel-header-foooter-pequeno').height($(window).height() - 70 - 416)
            $('.panel-body-dashboard-dr').height($(window).height() - 70 - 217)
            $('.panel-body-doutor-cidade').height($(window).height() - 70 - 270)
            $('.panel-body-relatorio-venda').height($(window).height() - 70 - 105 - 232)
            $('.panel-body-relatorio-no-header').height($(window).height() - 70 - 148 - 170)
            $('.panel-body-relatorio-header-duplo').height($(window).height() - 70 - 148 - 211)
            let heightLista = $('#header').height() + $('#footer').height() + $('#principal h3').height() + $('#panel-header').height() + $('#panel-footer').height() + $('#table-header').height() + 40
            let heightForm = $('#header').height() + $('#footer').height() + $('#principal h3').height() + $('#form-footer').height() + 40
            if (!$rootScope.disposivo_movel) {
                $('#table-lista-nova').height($(window).height() - heightLista)
                $('#form-body').height($(window).height() - heightForm)
            }
        }

        const map_data_nomes = {
            d: 'dias',
            h: 'horas',
            m: 'minutos',
            s: 'segundos'
        }

        $rootScope.get_grupo = function () {
            return $rootScope.usuario_logado.grupo
        }

        $rootScope.msg_validacao = function (status, falhas, id_elem) {
            if (status)
                return "Validado com sucesso"
            else {
                let msg_erro = []
                for (let indice in falhas) {
                    switch (indice) {
                        case 'cep':
                            msg_erro.push("- CEP inválido. Deve ser no formato XX.XXX-XXX")
                            break
                        case 'cpf':
                            msg_erro.push("- CPF inválido")
                            break
                        case 'cnpj':
                            msg_erro.push("- CNPJ inválido")
                            break
                        case 'conta':
                            msg_erro.push("- Conta inválida. Deve conter somente números.")
                            break
                        case 'conta_dv':
                            msg_erro.push("O dígito verificardor pode ser um número ou a letra X.")
                            break
                        case 'data':
                            msg_erro.push("- Data inválida. Deve ser no formato dd/mm/aaaa.")
                            break
                        case 'dt_diferenca_maior_que':
                            var [tempo, unidade] = $(id_elem).attr('diferenca-maior-que').split(" ")
                            msg_erro.push("- Data deve ser " + tempo + " " + map_data_nomes[unidade] + " anterior a data atual")
                            break
                        case 'dh':
                            msg_erro.push("- Data e hora inválida. Deve ser no formato dd/mm/aaaa hh:mm:ss, sendo a hora no formato 24h e os segundos são opcionais.")
                            break
                        case 'dh_diferenca_maior_que':
                            var [tempo, unidade] = $(id_elem).attr('diferenca-maior-que').split(" ")
                            msg_erro.push("- Data e hora deve ser, no mínimo, " + tempo + " " + map_data_nomes[unidade] + " anterior a data atual")
                            break
                        case 'dh_futura':
                            var [tempo, unidade] = $(id_elem).attr('futura').split(" ")
                            msg_erro.push("- Data e hora deve ser " + tempo + " " + map_data_nomes[unidade] + " posterior a data atual")
                            break
                        case 'digitos_maior_que':
                            var tamanho = $(id_elem).attr('maior-que')
                            msg_erro.push("- Deve ser maior que " + tamanho)
                            break
                        case 'digitos_menor_que':
                            var tamanho = $(id_elem).attr('menor-que')
                            msg_erro.push("- Deve ser menor que " + tamanho)
                            break
                        case 'duracao':
                            msg_erro.push("- Duração inválida.")
                            break
                        case 'email':
                            msg_erro.push("- E-mail inválido.")
                            break
                        case 'fone':
                            msg_erro.push("- Telefone inválido. Deve ser no formato (XX) X XXXX-XXXX para celular ou no formato (XX) XXXX-XXXX para fixo.")
                            break
                        case 'hora':
                            msg_erro.push("- Hora inválida.")
                            break
                        case 'igual_campo':
                            var igual_campo = $(id_elem).attr('campo-teste')
                            msg_erro.push("- Deve ser igual ao compo " + igual_campo)
                            break
                        case 'maxlength':
                            tamanho = $(id_elem).attr('maxlength')
                            msg_erro.push("- Deve ter no máximo " + tamanho + ' caracteres')
                            break
                        case 'menor_igual':
                            var tamanho = $(id_elem).attr('menor-igual')
                            msg_erro.push("- Deve ser menor ou igual a " + tamanho)
                            break
                        case 'minlength':
                            tamanho = $(id_elem).attr('minlength')
                            msg_erro.push("- Deve ter no mínimo " + tamanho + ' caracteres')
                            break
                        case 'nao_nulo':
                            msg_erro.push("- Preenchimento obrigatório.")
                            break
                        case 'nome_discourse':
                            msg_erro.push("- Possui caracteres inválidos. Pode conter apenas letras, números e _ (underscore). Não pode terminar em _ (underscore).")
                            break
                        case 'nome_discourse_termina_':
                            msg_erro.push("- Não pode terminar em _ (underscore).")
                            break
                        case 'numero_letra_traco_ponto':
                            msg_erro.push("- Contém caracteres inválidos. So pode conter número, letras, traço e ponto. Acentos e outros carecteres não são permitidos.")
                            break
                        case 'real_maior_que':
                            var tamanho = $(id_elem).attr('maior-que')
                            msg_erro.push("- Deve ser maior que " + tamanho)
                            break
                        case 'real_menor_que':
                            var tamanho = $(id_elem).attr('menor-que')
                            msg_erro.push("- Deve ser menor que " + tamanho)
                            break
                        case 'required':
                            msg_erro.push("- Preenchimento obrigatório")
                            break
                        case 'str_espaco_acento_numero':
                            msg_erro.push("- Contém caracteres inválidos. Só pode conter letras, acentos, espaços e números")
                            break
                        case 'str_espaco_acento_numero_linha':
                            msg_erro.push("- Contém caracteres inválidos. Só pode conter letras, acentos, espaços e números e multiplas linhas")
                            break
                        case 'str_nome_sobrenome':
                            msg_erro.push("- É preciso inserir ao menos um nome e um sobrenome")
                            break
                        case 'url':
                            msg_erro.push("- Contém caracteres inválidos. Só pode caracteres válidos em uma URL")
                            break
                        case 'vazio':
                            msg_erro.push("- Deve ser vazio")
                            break
                        case 'token_invalido':
                            msg_erro.push("- Token inválido")
                            break
                        default:
                            msg_erro.push("- BUG! Valor não previsto. Por favor reporte o problema para equipe de desenvolvimento")
                            break
                    }
                }
                return msg_erro.join("<br>")
            }
        }

        /** ====================
        * HELPERS
        ====================**/
        $rootScope.dr2supporter = function (string) {
            if (string) {
                string = string.replace("doutor", "supporter")
                string = string.replace("Doutor", "Supporter")
                return string
            }
        }

        $rootScope.custom_tooltip = function (sufixo) {
            return function (tooltipModel) {
                // Tooltip Element
                var tooltipEl = document.getElementById('chartjs-tooltip');

                // Create element on first render
                if (!tooltipEl) {
                    tooltipEl = document.createElement('div');
                    tooltipEl.id = 'chartjs-tooltip';
                    tooltipEl.innerHTML = "<table></table>";
                    document.body.appendChild(tooltipEl);
                }

                //Hide if no tooltip
                if (tooltipModel.opacity === 0) {
                    tooltipEl.style.opacity = 0;
                    return;
                }


                function getBody(bodyItem) {
                    return bodyItem.lines;
                }

                // Set Text
                if (tooltipModel.body) {
                    var bodyLines = tooltipModel.body.map(getBody);

                    var innerHtml = '<tbody>';

                    bodyLines.forEach(function (body, i) {
                        var style = 'color: #ffffff;';
                        style += 'background-color: #000000;';
                        style += 'border-color: #000000;';
                        style += 'border-width: 1px;';
                        style += 'border-radius: 2px;';
                        style += 'padding: 2px 2px 0px';
                        innerHtml += '<tr><td style="' + style + '">' + body + sufixo + '</td></tr>';
                    });
                    innerHtml += '</tbody>';

                    var tableRoot = tooltipEl.querySelector('table');
                    tableRoot.innerHTML = innerHtml;
                }

                // `this` will be the overall tooltip
                var position = this._chart.canvas.getBoundingClientRect();

                // Display, position, and set styles for font
                tooltipEl.style.opacity = 1;
                tooltipEl.style.position = 'absolute';
                tooltipEl.style.left = position.left + window.pageXOffset + tooltipModel.caretX + 'px';
                tooltipEl.style.top = position.top + window.pageYOffset + tooltipModel.caretY + 'px';
                tooltipEl.style.fontFamily = tooltipModel._bodyFontFamily;
                tooltipEl.style.fontSize = tooltipModel.bodyFontSize + 'px';
                tooltipEl.style.fontStyle = tooltipModel._bodyFontStyle;
                tooltipEl.style.padding = tooltipModel.yPadding + 'px ' + tooltipModel.xPadding + 'px';
            }
        }


        /** ====================
        * JSON 2 CSV
        ====================**/
        $rootScope.json2csv = json2csv


        /** ==============
        * SETA GRAVAR
        ==============**/
        $rootScope.gravar_clicado = false
        $rootScope.seta_gravar = function (parametro) {
            $rootScope.gravar_clicado = parametro
        }


        /** ====================
        * FILTROS BUSCA OTIMIZADO
        ====================**/
        $rootScope.otimiza_filtro = (filtro) => {
            if (!filtro)
                return {}
            let filtro_param = {}

            Object.keys(filtro).forEach((key) => {
                if (angular.isArray(filtro[key])) {
                    if (filtro[key].length > 0)
                        filtro_param[key] = filtro[key]
                }
                else if (filtro[key]) {
                    if (key.indexOf('data_') == 0)
                        filtro_param[key] = moment(filtro[key]).format('YYYY-MM-DD');
                    else
                        filtro_param[key] = filtro[key]
                }
            })
            return filtro_param
        }
        $rootScope.limpa_filtros = (filtro) => {
            Object.keys(filtro).forEach((key) => {
                delete filtro[key]
            })
        }

        $rootScope.get_ids = (lista) => {
            let lista_ids = []
            lista.forEach((item) => {
                lista_ids.push(item.id)
            })
            return lista_ids
        }

        /** ====================
        * GOOGLE ANALYTICS
        ====================**/
        $rootScope.tracking_ga = function () {
            if (config.app_mode == 'prod') {
                $window.ga('send', 'event', 'formulario-enviado-dr', 'click', 'formulario-enviado-dr')
            }
        }


        /** ==============
         * MODAL CONFIRMAÇAO
         ==============**/
        //  {msg, cb}
        $rootScope.modal_confirmacao = function (obj) {
            if (!obj.msg)
                obj.msg = 'Deseja remover este registro?'
            $rootScope.obj_confirmacao = obj
            $('#modal_confirmacao').modal('show')
        }

        $rootScope.confirmacao = function (status) {
            if (status)
                $rootScope.obj_confirmacao.cb($rootScope.obj_confirmacao)
            $rootScope.obj_confirmacao = {}
            $('#modal_confirmacao').modal('hide')
        }
        $rootScope.modal_confirmacao_cancela = function () {
            $rootScope.obj_confirmacao = {}
            $('#modal_confirmacao').modal('hide')
        }

        // LOGiN
        function valida_usuario(usuario) {
            if (!usuario) {
                alert("Informe usuário e senha")
                $('#login').focus()
                return false
            }
            if (!usuario.login) {
                alert("Informe o usuário")
                $('#login').focus()
                return false
            }
            if (!usuario.senha) {
                alert("Informe a senha")
                $('#senha').focus()
                return false
            }
        }

        // CHAT KOMMUNICATE
        $rootScope.chat = function () {

            if (localStorage.user) {

                const user = JSON.parse(localStorage.user)

                //MOCK PARA TESTES
                /* user.id_bot_km = "lia-mftys";
                user.km_app_id = "21fcc1fc911f7a0db6e8c2846f92ef82b"; */
                if (user.id_bot_km && user.km_app_id) {

                    /* const theme = "ns";  *///user.white_label;
                    // LOAD CHATBOT
                    (function (d, m) {
                        const apiKey = "SrPoYRF3AcQKtz3dg619xONycRVgkpjw";
                        /*---------------- Kommunicate settings start ----------------*/
                        var kommunicateSettings = {
                            appId: user.km_app_id,
                            automaticChatOpenOnNavigation: true,
                            popupWidget: true,
                            userName: user.nome,
                            email: user.email,
                            userId: user.perfil == "agregado_cliente" ? user.email : user.id,
                            ...translate,
                            onInit: function () {
                                /* ESCOLHENDO O BOT CERTO */
                                var defaultSettings = {
                                    defaultBotIds: [user.id_bot_km], // Replace <BOT_ID> with your bot ID which you can find in bot section of dashboard
                                    defaultAssignee: user.id_bot_km, // Replace <BOT_ID> with your bot ID which you can find in bot section of dashboard
                                    skipRouting: true,
                                };
                                Kommunicate.updateSettings(defaultSettings);
                                /*   DEFINIR CSS */
                                let css = "#launcher-svg-container,#km-popup-close-button { background-color: #00aaaa !important;}.mck-box-top,.mck-msg-right .mck-msg-box,#mck-msg-new { background: #00aaaa !important; }.mck-box-title,#mck-tab-title,.mck-msg-right .mck-msg-box { color: #fff !important;}#mck-agent-status-text {color: rgba(55, 57, 70, 0.5);}#km-chat-widget-close-button svg,#mck-conversation-back-btn svg { fill: #184869;}.mck-message-inner,#mck-sidebox-ft { background: #f9f9f9 !important;}"
                                Kommunicate.customizeWidgetCss(css);
                                var chatContext = {
                                    id_usuario: user.id,
                                    nome: user.nome,
                                    email: user.email,
                                };
                                Kommunicate.updateChatContext(chatContext);
                                let url = "https://services.kommunicate.io/rest/ws/user/update";
                                let headers = {
                                    "Api-Key": apiKey,
                                    "Of-User-Id": window.kommunicate._globals.userId,
                                    "Content-Type": "application/json",
                                };
                                let data = {
                                    displayName: user.nome,
                                    email: user.email,
                                    metadata: {
                                        id: user.id,
                                        nome: user.nome,
                                        email: user.email,
                                        cliente: user.cliente,
                                    },
                                };
                                let ob = {
                                    method: "POST",
                                    headers: headers,
                                    body: JSON.stringify(data),
                                };
                                fetch(url, ob);
                            },
                        };
                        /*----------------- Kommunicate settings end ------------------*/
                        var s = document.createElement("script");
                        s.type = "text/javascript";
                        s.async = true;
                        s.src = "https://widget.kommunicate.io/v2/kommunicate.app";
                        var h = document.getElementsByTagName("head")[0];
                        h.appendChild(s);
                        window.kommunicate = m;
                        m._globals = kommunicateSettings;
                    })(document, window.kommunicate || {});

                }


            }
        }

        $rootScope.login = function (usuario) {
            $rootScope.autenticando = false
            if (valida_usuario(usuario) == false) {
                $scope.autenticando = false
                return
            }
            if (usuario.login.slice(-1) == '@')
                usuario.login = usuario.login + 'netsupport.com.br'

            $http({
                url: config.base_url + '/sessao',
                method: 'POST',
                data: usuario
            }).success(function (response) {

                $rootScope.recarrega_dados_usuario_logado(true)
                $rootScope.autenticando = false

                // buscar dados do usuario
                $http({
                    url: config.base_url + '/sessao/minha',
                    method: 'get'
                }).success(function (r) {
                    localStorage.setItem('user', JSON.stringify(r.dados))
                    $rootScope.usuario = r.dados

                    if (r.dados.perfil != 'funcionario') {
                        let loginEnc = []
                        let passEnc = []

                        usuario.login.split("").filter(r => loginEnc.push(r.charCodeAt()))
                        usuario.senha.split("").filter(r => passEnc.push(r.charCodeAt()))

                        let login = loginEnc.join('-')
                        let pass = passEnc.join('-')
                        let url = `${r.dados.url_frontend}?user=${login}&pass=${pass}`;
                        localStorage.setItem('redirect', url)
                        window.location = '/#/logout'
                        return
                        /*
                            $rootScope.mostra_mensagem('', `<div style="text-align:center">
                        <img src="/img/logo.png" width="150" />
                       <br>
                        <br>
                        Olá <b>${r.dados.nome}</b>.<br><h3>Temos uma versão nova do portal para você!</h3>
                         Encontra-se disponível no endereço <b>
                         <a href="${r.dados.url_frontend}?user=${login}&pass=${pass}">${r.dados.url_frontend}</a></b>.
                         <br>
                         Atualize seus favoritos! </div>`, [{ url_destino: r.dados.url_frontend + `?user=${login}&pass=${pass}`, titulo: 'Ir' }])
                         */

                    }
                    $rootScope.chat()
                })

            }).error(function (response) {
                alert(response.msg)
                $rootScope.autenticando = false
            })
        }

        $rootScope.usuario = JSON.parse(localStorage.getItem('user'))

        //FUNÇÂO PARA HABILITAR FuNÇÂO WHATSAPP
        $rootScope.whatsapp = function (auth) {

            $rootScope.usuario.autoriza_whatsapp = auth
            localStorage.setItem('user', JSON.stringify($rootScope.usuario))
            $http({
                url: config.base_url + '/doutor/autoriza_whatsapp',
                method: 'POST',
                data: { autoriza: auth }
            }).success(() => {
                if (auth) window.open('https://api.whatsapp.com/send?phone=553484419437&text=Aceito%20receber%20chamados%20por%20WhatsApp!%21', '_blank')
            })

        }

        $rootScope.goto_login = function () {
            $location.path('/')
        }


        $rootScope.debug("app.js carregado")
    })
