app.controller("tipo_servico", function ($scope, $rootScope, $http, $location, config, $route, $routeParams) {

    $scope.tipo_servicos = []
    $scope.especialidades = []
    $scope.limpa_filtros = function () {
        $scope.filtros = {}
    }

    $scope.init_tipo_servico = function () {
        $scope.tipo_servico = {}
    }

    $scope.lista = function (filtros) {
        let filtro_otimizado = $rootScope.otimiza_filtro(filtros)
        $http({
            url: config.base_url + "/tipo_servico/lista",
            method: 'POST',
            data: filtro_otimizado
        }).success(function (data, status) {
            $scope.tipo_servicos = data
        }).error(function (data, status) {
            $scope.tipo_servicos = []
            $rootScope.erro_portal(data, status)
        })
    }

    $scope.adiciona = function (tipo_servico) {
        $http({
            url: config.base_url + "/tipo_servico",
            method: 'POST',
            data: tipo_servico
        }).success(function (data, status) {
            $rootScope.adiciona_historico(data, status)
            $scope.init_tipo_servico()
        }).error(function (data, status) {
            $rootScope.erro_portal(data, status)
        })
    }

    $scope.atualiza_custo_orcado = function (field, value) {

        $scope.tipo_servico[field] = parseFloat(value * ($scope.tipo_servico.proporcao_custo_orcado / 100)).toFixed(2)

        /*  $scope.tipo_servico.valor_job_interior = $scope.tipo_servico.valor_orcado_job_interior * ($scope.proporcao_custo_orcado / 100)
         $scope.tipo_servico.valor_job_interior_diferenciado = $scope.tipo_servico.valor_orcado_job_interior_diferenciado * ($scope.proporcao_custo_orcado / 100)
         $scope.tipo_servico.valor_extra_interior = $scope.tipo_servico.valor_orcado_extra_interior * ($scope.proporcao_custo_orcado / 100)
         $scope.tipo_servico.valor_extra_interior_diferenciado = $scope.tipo_servico.valor_orcado_extra_interior_diferenciado * ($scope.proporcao_custo_orcado / 100)
         $scope.tipo_servico.valor_job_capital = $scope.tipo_servico.valor_orcado_job_capital * ($scope.proporcao_custo_orcado / 100)
         $scope.tipo_servico.valor_job_capital_diferenciado = $scope.tipo_servico.valor_orcado_job_capital_diferenciado * ($scope.proporcao_custo_orcado / 100)
         $scope.tipo_servico.valor_extra_capital = $scope.tipo_servico.valor_orcado_extra_capital * ($scope.proporcao_custo_orcado / 100)
         $scope.tipo_servico.valor_extra_capital_diferenciado = $scope.tipo_servico.valor_orcado_extra_capital_diferenciado * ($scope.proporcao_custo_orcado / 100)
         console.log($scope.tipo_servico); */
    }

    $scope.custo_orcado = function () {
        $http({
            url: config.base_url + "/tipo_servico/proporcao_custo_orcado",
            method: 'GET'
        }).success(function (data, status) {
            $scope.tipo_servico.proporcao_custo_orcado = data.proporcao_custo_orcado * 100
        })
            .error(function (data, status) {
                $rootScope.erro_portal(data, status)
            })
    }

    $scope.get = function (id_tipo_servico) {
        $http({
            url: config.base_url + "/tipo_servico/" + id_tipo_servico,
            method: 'GET'
        }).success(function (data, status) {
            $scope.tipo_servico = data
            $scope.tipo_servico.proporcao_custo_orcado = data.proporcao_custo_orcado * 100

            /* $scope.tipo_servico.valor_job_interior = data.tipo_servico.valor_orcado_job_interior * data.proporcao_custo_orcado
            $scope.tipo_servico.valor_job_interior_diferenciado = data.tipo_servico.valor_orcado_job_interior_diferenciado * data.proporcao_custo_orcado
            $scope.tipo_servico.valor_extra_interior = data.tipo_servico.valor_orcado_extra_interior * data.proporcao_custo_orcado
            $scope.tipo_servico.valor_extra_interior_diferenciado = data.tipo_servico.valor_orcado_extra_interior_diferenciado * data.proporcao_custo_orcado
            $scope.tipo_servico.valor_job_capital = data.tipo_servico.valor_orcado_job_capital * data.proporcao_custo_orcado
            $scope.tipo_servico.valor_job_capital_diferenciado = data.tipo_servico.valor_orcado_job_capital_diferenciado * data.proporcao_custo_orcado
            $scope.tipo_servico.valor_extra_capital = data.tipo_servico.valor_orcado_extra_capital * data.proporcao_custo_orcado */


        })
            .error(function (data, status) {
                $rootScope.erro_portal(data, status)
            })
    }

    $scope.altera = function (tipo_servico) {
        $http({
            url: config.base_url + "/tipo_servico/" + tipo_servico.id,
            method: 'PUT',
            data: tipo_servico
        }).success(function (data, status) {
            $rootScope.adiciona_historico(data, status)
            $scope.get(tipo_servico.id)
        })
            .error(function (data, status) {
                $rootScope.erro_portal(data, status)
            })
    }

    $scope.desativa = function ({ id_tipo_servico }) {
        $http({
            url: config.base_url + "/tipo_servico/" + id_tipo_servico,
            method: 'DELETE'
        }).success(function (data, status) {
            $rootScope.adiciona_historico(data, status)
            $scope.lista($scope.filtros)
        })
            .error(function (data, status) {
                $rootScope.erro_portal(data, status)
            })
    }

    $scope.ativa = function (id_tipo_servico) {
        $http({
            url: config.base_url + "/tipo_servico/" + id_tipo_servico + "/status",
            method: 'PUT'
        }).success(function (data, status) {
            $rootScope.adiciona_historico(data, status)
            $scope.lista($scope.filtros)
        })
            .error(function (data, status) {
                $rootScope.erro_portal(data, status)
            })
    }


    $scope.lista_especialidade = function (insere_selecione) {
        $http({
            url: config.base_url + "/especialidade/lista",
            method: 'POST'
        }).success(function (data, status) {
            if (insere_selecione)
                data.splice(0, 0, { id: undefined, nome: "SELECIONE" })
            $scope.especialidades = data
        }).error(function (data, status) {
            $scope.especialidades = []
            $rootScope.erro_portal(data, status)
        })
    }

    $scope.lista_base_custo = function (insere_selecione) {
        $http({
            url: config.base_url + "/base_custo/lista",
            method: 'POST'
        }).success(function (data, status) {
            if (insere_selecione)
                data.splice(0, 0, { id: undefined, nome: "SELECIONE" })
            $scope.bases_custo = data
        })
            .error(function (data, status) {
                $rootScope.erro_portal(data, status)
            })
    }

    $scope.reseta_duracao_alocacao = function () {
        if ($scope.tipo_servico.tipo == 'atendimento') {
            $scope.tipo_servico.duracao_alocacao = 1
            $scope.tipo_servico.duracao_folga = '00:00'
        }
    }

    $scope.reseta_valor_deslocamento = function () {
        if (['variavel', 'zero'].indexOf($scope.tipo_servico.tipo_deslocamento) >= 0) {
            $scope.tipo_servico.valor_deslocamento_fixo = 0.00
        }
    }

    $scope.lista_base_custo(true)
    $scope.lista_especialidade(true)
    $scope.init_tipo_servico()

    // Detalha
    if ($route.current.originalPath == '/tipo_servico/:id_tipo_servico') {
        $scope.get($routeParams.id_tipo_servico)
    } else {
        $scope.custo_orcado()
    }
})
